<template>
    <section class="page-section portfolio" id="gallery" style="background-color:#7bbce1; padding: 0px;">
        <div class="container">
            <!-- Portfolio Section Heading-->
            <!--<div id="ambiti_app">-->
            <div class="row">
                <h5 class="page-section-heading text-center text-uppercase  mb-0" style="padding-top:15px; font-size: 25px; color:#FFF; background-color:#7bbce1; -webkit-border-top-left-radius: 20px;
-webkit-border-top-right-radius: 20px;
-moz-border-radius-topleft: 20px;
-moz-border-radius-topright: 20px;
border-top-left-radius: 20px;
border-top-right-radius: 20px;">Ambiti
                    di Applicazione</h5>
            </div>
            <!-- Icon Divider-->
            <!-- <div class="divider-custom">
                <div class="divider-custom-line"></div>
                <div class="divider-custom-icon"><i class="fas fa-star"></i></div>
                <div class="divider-custom-line"></div>
            </div> -->

            <div class="row" style="background-color:#7bbce1; padding: 20px; -webkit-border-bottom-right-radius: 20px;
-webkit-border-bottom-left-radius: 20px;
-moz-border-radius-bottomright: 20px;
-moz-border-radius-bottomleft: 20px;
border-bottom-right-radius: 20px;
border-bottom-left-radius: 20px;">


                <!-- Card 2 -->
                <div class="col-md-3 card-container">
                    <div class="card-flip">
                        <!-- Card 2 Front -->
                        <div class="card front">
                            <img src="../assets/img/gallery_1.jpeg" class="card-img-top img-fluid">
                            <div class="card-block p-3">
                                <h4 class="card-title">Settore agro-alimentare e bevande</h4>
                                <p class="card-text"><small>Diverse sono le applicazioni dei gas industriali nel settore
                                        agro-alimentare e delle bevande: nelle
                                        serre (arricchimento con Co2), nella disinfestazione delle colture dai principali
                                        parassiti,
                                        nell’allevamento dei pesci, nei processi di raffreddamento e surgelazione, nel
                                        confezionamento in
                                        atmosfera protettiva (MAP), nel trasporto refrigerato e nella gassatura delle
                                        bevande.</small></p>
                            </div>
                        </div>
                        <!-- End Card 2 Front -->

                        <!-- Card 2 Back -->
                        <div class="card back">
                            <div class="card-block p-3">
                                <h4 class="card-title">Settore agro-alimentare e bevande</h4>
                                <p class="card-text"><small>Diverse sono le applicazioni dei gas industriali nel settore
                                        agro-alimentare e delle bevande: nelle
                                        serre (arricchimento con Co2), nella disinfestazione delle colture dai principali
                                        parassiti,
                                        nell’allevamento dei pesci, nei processi di raffreddamento e surgelazione, nel
                                        confezionamento in
                                        atmosfera protettiva (MAP), nel trasporto refrigerato e nella gassatura delle
                                        bevande.</small>
                                </p>
                            </div>
                        </div>
                        <!-- End Card 2 Back -->
                    </div>
                </div>
                <!-- End Card 2 -->

                <!-- Card 2 -->
                <div class="col-md-3 card-container">
                    <div class="card-flip">
                        <!-- Card 2 Front -->
                        <div class="card front">
                            <img src="../assets/img/gallery_2.jpeg" class="card-img-top img-fluid">
                            <div class="card-block p-3">
                                <h4 class="card-title">Saldatura e Taglio</h4>
                                <p class="card-text"><small>I principali metodi di saldatura e taglio da tempo prevedono
                                        l’utilizzo di diversi gas industriali per
                                        ottimizzarne processi e risultati.</small></p>
                            </div>
                        </div>
                        <!-- End Card 2 Front -->

                        <!-- Card 2 Back -->
                        <div class="card back">
                            <div class="card-block p-3">
                                <h4 class="card-title">Saldatura e taglio</h4>
                                <p class="card-text"><small>I principali metodi di saldatura e taglio da tempo prevedono
                                        l’utilizzo di diversi gas industriali per
                                        ottimizzarne processi e risultati.</small>
                                </p>
                            </div>
                        </div>
                        <!-- End Card 2 Back -->
                    </div>
                </div>
                <!-- End Card 2 -->

                <!-- Card 2 -->
                <div class="col-md-3 card-container">
                    <div class="card-flip">
                        <!-- Card 2 Front -->
                        <div class="card front">
                            <img src="../assets/img/gallery_3.jpeg" class="card-img-top img-fluid">
                            <div class="card-block p-3">
                                <h4 class="card-title">Refrigerazione e condizionamento</h4>
                                <p class="card-text"><small>Le applicazioni dei gas refrigeranti riguardano i settori del
                                        condizionamento e della refrigerazione.
                                        In particolare, negli ultimi anni, le tecnologie sulle quali si basano gli impianti
                                        di condizionamento,
                                        di refrigerazione e le pompe di calore iniziano a orientarsi verso l’impiego di gas
                                        refrigeranti più
                                        ecologici per ridurre l’impatto ambientale.</small></p>
                            </div>
                        </div>
                        <!-- End Card 2 Front -->

                        <!-- Card 2 Back -->
                        <div class="card back">
                            <div class="card-block p-3">
                                <h4 class="card-title">Refrigerazione</h4>
                                <p class="card-text"><small>Le applicazioni dei gas refrigeranti riguardano i settori del
                                        condizionamento e della refrigerazione.
                                        In particolare, negli ultimi anni, le tecnologie sulle quali si basano gli impianti
                                        di condizionamento,
                                        di refrigerazione e le pompe di calore iniziano a orientarsi verso l’impiego di gas
                                        refrigeranti più
                                        ecologici per ridurre l’impatto ambientale.</small>
                                </p>
                            </div>
                        </div>
                        <!-- End Card 2 Back -->
                    </div>
                </div>
                <!-- End Card 2 -->

                <!-- Card 2 -->
                <div class="col-md-3 card-container">
                    <div class="card-flip">
                        <!-- Card 2 Front -->
                        <div class="card front">
                            <img src="../assets/img/gallery_4.png" class="card-img-top img-fluid">
                            <div class="card-block p-3">
                                <h4 class="card-title">Antincendio</h4>
                                <p class="card-text"><small>I gas tecnici vengono utilizzati nell&#39;antincendio per
                                        proteggere le persone, le attrezzature e le
                                        strutture da eventuali incendi, creando un ambiente a basso contenuto di ossigeno,
                                        in modo che il
                                        fuoco non possa propagarsi.
                                        Alcuni dei gas tecnici usati in queste applicazioni sono l&#39;azoto, l&#39;argon e
                                        l&#39;anidride carbonica.</small></p>
                            </div>
                        </div>
                        <!-- End Card 2 Front -->

                        <!-- Card 2 Back -->
                        <div class="card back">
                            <div class="card-block p-3">
                                <h4 class="card-title">Antincendio</h4>
                                <p class="card-text"><small>I gas tecnici vengono utilizzati nell&#39;antincendio per
                                        proteggere le persone, le attrezzature e le
                                        strutture da eventuali incendi, creando un ambiente a basso contenuto di ossigeno,
                                        in modo che il
                                        fuoco non possa propagarsi.
                                        Alcuni dei gas tecnici usati in queste applicazioni sono l&#39;azoto, l&#39;argon e
                                        l&#39;anidride carbonica.</small>
                                </p>
                            </div>
                        </div>
                        <!-- End Card 2 Back -->
                    </div>
                </div>
                <!-- End Card 2 -->





            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Gallery'
}
</script>