<template>
    <div>
        <div class="form-row" v-if="index <= misceleInserite - 1">
            <div class="form-group col-md-4 input-group-lg">
                <label for="miscela">Miscela {{ index + 1 }}</label>
                <select name="miscela" class="form-control" v-model="cfg.miscela" @change="onChangeMiscela($event)">
                    <option value="0">-</option>
                    <option value="1">A filo</option>
                    <option value="2">A Tig/Mig</option>
                    <option value="3">Miscela Alimentare</option>
                    <option value="4">Altro</option>
                </select>
            </div>
            <div class="form-group col-md-4 input-group-lg">
                <label for="contenitore">Contenitore</label>
                <select class="form-control" v-model="cfg.contenitore" @change="onChangeContenitore($event)">
                    <option value="0">-</option>
                    <option value="1">Bombola (da 1 lt a 27 lt)</option>
                    <option value="2">Bombola (da 30 lt a 50 lt)</option>
                    <option value="3">Gruppi</option>
                </select>
            </div>
            <div class="form-group col-md-4 input-group-lg">
                <label for="applicazione">Applicazione</label>
                <select class="form-control" v-model="cfg.applicazione" @change="onChangeApplicazione($event)">
                    <option value="0">-</option>
                    <option value="1">Industriale</option>
                    <option value="2">Medicale</option>
                    <option value="3">Alimentare</option>
                    <option value="4">Antincendio</option>
                </select>
            </div>
            <div class="form-group col-md-6 input-group-lg">
                <label for="consumo">Consumo <span class="text-info">(**)</span></label>
                <input type="text" class="form-control" v-model="cfg.consumo" @change="onChangeConsumo($event)">
            </div>
            <div class="form-group col-md-6 input-group-lg">
                <label for="nome_commerciale">Nome Commerciale</label>
                <input type="text" class="form-control" v-model="cfg.nome_commerciale"
                    @change="onChangeNomeCommerciale($event)">
            </div>

            <div class="form-group col-md-2 input-group-lg">
                <label for="gas"><span class="text-info"><br /></span></label>
                <button class="btn btn-sm btn-danger" type="button" style="margin-top:30px"
                    v-if="misceleInserite == index + 1 && index > 0" @click="UpdateMisceleInserite('remove')"><i
                        class="fas fa-trash fa-sm"></i></button>&nbsp;&nbsp;
                <button class="btn btn-sm btn-info" type="button" style="margin-top:30px"
                    v-if="misceleInserite == index + 1 && ((index + 1) < 3)" @click="UpdateMisceleInserite('add')"><i
                        class="fas fa-plus fa-sm"></i></button>
            </div>
        </div>
        <!-- <div v-if="index == 0">
            <hr class="separatore" />
        </div> -->
    </div>
</template>

<script>
export default {
    name: "miscela",
    props: {
        cfg: Object,
        index: Number,
        misceleInserite: Number
    },
    methods: {
        onChangeMiscela(event) {
            this.cfg.miscela = event.target.value;
            this.$emit('cfg-miscela', this.cfg);
        },
        onChangeContenitore(event) {
            this.cfg.contenitore = event.target.value;
            this.$emit('cfg-miscela', this.cfg);
        },
        onChangeApplicazione(event) {
            this.cfg.applicazione = event.target.value;
            this.$emit('cfg-miscela', this.cfg);
        },
        onChangeConsumo(event) {
            this.cfg.consumo = event.target.value;
            this.$emit('cfg-miscela', this.cfg);
        },
        onChangeNomeCommerciale(event) {
            this.cfg.nome_commerciale = event.target.value;
            this.$emit('cfg-miscela', this.cfg);
        },
        UpdateMisceleInserite(event) {
            this.misceleInserite = (event == 'remove') ? this.misceleInserite - 1 : this.misceleInserite + 1;
            this.$emit('UpdateMisceleInserite', this.misceleInserite);
        }
    }
};
</script>

<style scoped>
.my-box {
    margin-bottom: 20px;
}
</style>
