<template>
  <div>
    <div class="form-row" v-if="index <= gasInseriti - 1">
      <div class="form-group col-md-4 input-group-lg">
        <label for="gas">Gas {{ index + 1 }}</label>
        <select name="gas" class="form-control" v-model="cfg.gas" @change="onChangeGas($event)">
          <option value="0">-</option>
          <option value="1">Ossigeno</option>
          <option value="2">Argon</option>
          <option value="3">Azoto</option>
          <option value="4">Anidride Carbonica CO2</option>
          <option value="5">Elio</option>
          <option value="6">Idrogeno</option>
          <option value="7">Acetilene</option>
          <option value="8">Propano</option>
          <option value="9">ALTRO <small>(indicare nome commerciale)</small></option>
        </select>
        <div v-if="cfg.gas == 9">
          <label>Nome Commerciale</label>
          <input type="text" class="form-group form-control" v-model="cfg.nome_commerciale">
        </div>
      </div>
      <div class="form-group col-md-2 input-group-lg">
        <label for="contenitore">Contenitore</label>
        <select class="form-control" v-model="cfg.contenitore" @change="onChangeContenitore($event)">
          <option value="0">-</option>
          <option value="1">Bombola (da 1 lt a 27 lt)</option>
          <option value="2">Bombola (da 30 lt a 50 lt)</option>
          <option value="3">Gruppi</option>
          <option value="4">Dewar</option>
          <option value="5">Serbatoio</option>
        </select>
      </div>
      <div class="form-group col-md-2 input-group-lg">
        <label for="applicazione">Applicazione</label>
        <select class="form-control" v-model="cfg.applicazione" @change="onChangeApplicazione($event)">
          <option value="0">-</option>
          <option value="1">Industriale</option>
          <option value="2">Medicinali</option>
          <option value="3">Alimentare</option>
          <option value="4">Antincendio</option>
        </select>
      </div>
      <div class="form-group col-md-2 input-group-lg">
        <label for="gas">Consumo <span class="text-info">(**)</span></label>
        <input type="text" class="form-control" v-model="cfg.consumo" @change="onChangeConsumo($event)">
      </div>
      <div class="form-group col-md-2 input-group-lg">
        <label for="gas"><span class="text-info"><br /></span></label>
        <button class="btn btn-sm btn-danger" type="button" style="margin-top:30px"
          v-if="gasInseriti == index + 1 && index > 0" @click="UpdateGasInseriti('remove')"><i
            class="fas fa-trash fa-sm"></i></button>&nbsp;&nbsp;
        <button class="btn btn-sm btn-info" type="button" style="margin-top:30px"
          v-if="gasInseriti == index + 1 && ((index + 1) < 10)" @click="UpdateGasInseriti('add')"><i
            class="fas fa-plus fa-sm"></i></button>
      </div>
    </div>

    <!-- <div v-if="index >= 0 && index <= 8">
      <hr class="separatore" />
    </div> -->
  </div>
</template>

<script>
export default {
  name: "gas",
  props: {
    cfg: Object,
    index: Number,
    gasInseriti: Number
  },
  methods: {
    onChangeGas(event) {
      this.cfg.gas = event.target.value;
      this.$emit('cfg-gas', this.cfg);
    },
    onChangeContenitore(event) {
      this.cfg.contenitore = event.target.value;
      this.$emit('cfg-gas', this.cfg);
    },
    onChangeApplicazione(event) {
      this.cfg.applicazione = event.target.value;
      this.$emit('cfg-gas', this.cfg);
    },
    onChangeConsumo(event) {
      this.cfg.consumo = event.target.value;
      this.$emit('cfg-gas', this.cfg);
    },
    UpdateGasInseriti(event) {
      this.gasInseriti = (event == 'remove') ? this.gasInseriti - 1 : this.gasInseriti + 1;
      this.$emit('UpdateGasInseriti', this.gasInseriti);
    }
  }
};
</script>

<style scoped>
.my-box {
  margin-bottom: 20px;
}
</style>
