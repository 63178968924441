<template>
    <section class="page-section mb-0" id="about">
        <div class="container">
            <!-- About Section Heading-->
            <h5 class="page-section-heading text-center text-uppercase mb-0" style="font-size: 25px; color:#7bbce1">CHI
                SIAMO</h5>
            <br />
            <br />
            <!-- Icon Divider-->
            <!-- <div class="divider-custom divider-dark">
                <div class="divider-custom-line"></div>
                <div class="divider-custom-icon"><i class="fas fa-star"></i></div>
                <div class="divider-custom-line"></div>
            </div> -->
            <!-- About Section Content-->
            <div class="row">
                <div class="col-sm-2"></div>
                <div class="col-sm-8">
                    <p style="font-size:14px !important;">
                        Virtual Gas è una giovane società tecnologica italiana che opera come sito di comparazione,
                        promozione e intermediazione di fornitura di gas tecnici e industriali.
                        Fondata di recente da professionisti con pluriennale esperienza nel settore, Virtual Gas si pone al
                        fianco delle aziende con l’obiettivo di offrire un servizio personalizzato, gratuito e
                        rapido.<br /><br />
                        Grazie alla <strong>collaborazione con selezionati partner commerciali, presenti</strong> su tutto
                        il
                        territorio
                        nazionale <strong>Virtualgas.it</strong> seleziona la miglior offerta commerciale per <strong>gas
                            relativi ad applicazioni
                            industriali.</strong><br /><br />
                        Vuoi diventare un nostro Partner?&nbsp; <button type="button" class="btn btn-danger btn-sm"
                            @click="inviaEmailDiventaPartner"
                            style="color: #FFF; background-color:#176ae0; border-color: #176ae0; font-weight: bold;">CONTATTACI</button>

                    </p>

                </div>
                <div class="col-sm-2"></div>
                <!-- <div class="col-lg-4 ms-auto">
                    <p class="lead">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea et cumque minus excepturi
                        facere, quasi libero fugit eveniet aut, at explicabo nemo id! Beatae nulla earum numquam, architecto
                        itaque labore!</p>
                </div>
                <div class="col-lg-4 me-auto">
                    <p class="lead">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eveniet dignissimos numquam
                        assumenda! Sapiente, assumenda iure? Ipsum dignissimos vitae consequatur suscipit. Exercitationem
                        blanditiis a ut quibusdam et laboriosam fugiat delectus sit?</p>
                </div> -->
            </div>
            <!-- About Section Button-->
            <!-- <div class="text-center mt-4">
                    <a class="btn btn-xl btn-outline-light" href="https://startbootstrap.com/theme/freelancer/">
                        <i class="fas fa-download me-2"></i>
                        Richiedici una quotazione
                    </a>
                </div> -->
        </div>
    </section>
</template>

<script>
export default {
    name: 'ChiSiamo',
    methods: {
        inviaEmailDiventaPartner() {
            window.location.assign("mailto:commerciale@virtualgas.it");
        }
    }
}
</script>