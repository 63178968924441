<template>
    <!-- Navigation-->
    <nav class="navbar navbar-expand-lg bg-white text-uppercase fixed-top" id="mainNav">
        <div class="container">
            <a class="navbar-brand" href="#page-top"><img class="masthead-avatar mb-5" src="../assets/img/virtualgas.jpg"
                    alt="..." style="width: 310px !important; margin-bottom: 0px !important;" /></a>
            <button class="navbar-toggler text-uppercase font-weight-bold bg-primary text-white rounded" type="button"
                data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive"
                aria-expanded="false" aria-label="Toggle navigation">
                Menu
                <i class="fas fa-bars"></i>
            </button>
            <div class="collapse navbar-collapse" id="navbarResponsive">
                <ul class="navbar-nav ms-auto">
                    <li class="nav-item mx-0 mx-lg-1"><a class="nav-link py-3 px-0 px-lg-3 rounded"
                            href="https://www.virtualgas.it/customer/">Richiedi quotazione</a></li>
                    <li class="nav-item mx-0 mx-lg-1"><a class="nav-link py-3 px-0 px-lg-3 rounded"
                            href="#newsLettersBanner">News</a></li>
                    <!-- <li class="nav-item mx-0 mx-lg-1"><a class="nav-link py-3 px-0 px-lg-3 rounded"
                            href="#portfolio">Diventa Partner</a></li> -->
                    <li class="nav-item mx-0 mx-lg-1"><a class="nav-link py-3 px-0 px-lg-3 rounded" href="#about">Chi
                            Siamo</a></li>
                    <li class="nav-item mx-0 mx-lg-1 "><a class="nav-link py-3 px-0 px-lg-3 rounded button"
                            href="https://virtualgas.it/customer" target="_blank"><button type="button"
                                class="btn btn-danger btn-sm"
                                style="color: #FFF; background-color:#176ae0; border-color: #176ae0; font-weight: bold;"
                                title="Registrati oppure accedi alla tua area riservata">Area
                                Riservata</button></a></li>
                </ul>
            </div>
        </div>

    </nav>
</template>

<script>
export default {
    name: 'Menu'
}
</script>