<template>
      <div class="py-4 text-center text-white" style="background-color: #7bbce1;">
            <div class="container"><small>Copyright &copy; VirtualGas 2023</small></div>
      </div>
</template>

<script>
export default {
      name: 'CopyRight'
}
</script>
