<template>
    <!-- Dettagli -->
    <div>
        <div class="col-md-6 form-row float-left">
            <div class="col-md-12 form-group"><label>Giacenza bombole (*)</label></div>
            <div class="col-md-6 float-left input-group-lg form-group">
                <input type="number" class="form-control" id="" placeholder="" v-model="cfg.bombole_piene"
                    @change="onChangeBombolePiene($event)" />
                <small class=" form-text text-muted">
                    piene
                </small>
                <span v-if="msg.bombole_piene" class="text-danger">{{ msg.bombole_piene }}</span>
            </div>
            <div class="col-md-6 float-left input-group-lg form-group">
                <input type="number" class="form-control" id="" placeholder="" v-model="cfg.bombole_vuote"
                    @change="onChangeBomboleVuote($event)" />
                <small id="" class="form-text text-muted">
                    vuote
                </small>
                <span v-if="msg.bombole_vuote" class="text-danger">{{ msg.bombole_vuote }}</span>
            </div>
        </div>

        <div class="col-md-6 float-left form-row">
            <div class="col-md-12 form-group"><label>Giacenza gruppi (*)</label></div>
            <div class="col-md-6 float-left input-group-lg form-group">
                <input type="number" class="form-control" id="" placeholder="" v-model="cfg.gruppi_pieni"
                    @change="onChangeGruppiPieni($event)" />
                <small id="" class="form-text text-muted">
                    piene
                </small>
            </div>
            <div class="col-md-6 float-left input-group-lg form-group">
                <input type="number" class="form-control" id="" placeholder="" v-model="cfg.gruppi_vuoti"
                    @change="onChangeGruppiVuoti($event)" />
                <small id="" class="form-text text-muted">
                    vuote
                </small>
            </div>
        </div>
        <div class="clearfix"></div>
        <hr class="separatore" />
        <div><br /></div>
        <div class="form-row">
            <div class="form-group col-md-4 input-group-lg">
                <label for="citta">Consegna media di bombole</label>
                <input type="text" class="form-control" v-model="cfg.consegna_media_bombole"
                    @change="onChangeConsegnaMediaBombole($event)" />
            </div>
            <div class="form-group col-md-4 input-group-lg">
                <label for="cap">Consegna media di gruppi</label>
                <input type="text" class="form-control" v-model="cfg.consegna_media_gruppi"
                    @change="onChangeConsegnaMediaGruppi($event)" />
            </div>
            <div class="form-group col-md-4 input-group-lg">
                <label for="provincia">Consegna media lt liquido</label>
                <input type="text" name="provincia" class="form-control" v-model="cfg.consegna_media_liquido"
                    @change="onChangeConsegnaMediaLiquido($event)" />
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-md-4 input-group-lg">
                <label for="nazione">Attuale fornitore</label>
                <input type="text" class="form-control" v-model="cfg.fornitore" @change="onChangeFornitore($event)" />
            </div>
            <div class="form-group col-md-4 input-group-lg">
                <label for="citta">Attuali condizioni pagamento</label>
                <input type="text" class="form-control" v-model="cfg.pagamento" @change="onChangePagamento($event)" />
            </div>
            <div class="form-group col-md-4 input-group-lg">
                <label for="cap">Scadenza contratto</label>
                <input type="date" class="form-control" v-model="cfg.scadenza_contratto"
                    @change="onChangeScadenzaContratto($event)" />
                <span v-if="msg.scadenza_contratto" class="text-danger">{{ msg.scadenza_contratto }}</span>
            </div>
        </div>
        <br />
        <div class="text-center">
            <label>consegna merce a mezzo di : (*) &nbsp; &nbsp; &nbsp; &nbsp;</label>
            <div class="form-check form-check-inline">
                <input class="form-check-input input-group-lg" type="radio" name="inlineRadioOptions" id="inlineRadio1"
                    value="option1">
                <label class="form-check-label input-group-lg" for="inlineRadio1">consegna fornitore</label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input input-group-lg" type="radio" name="inlineRadioOptions" id="inlineRadio2"
                    value="option2">
                <label class="form-check-label" for="inlineRadio2">ritiro per conto proprio</label>
            </div>
        </div>
        <br />
        <div class="form-row">
            <div class="form-group col-md-12 input-group-lg">
                <label for="cap">Note</label>
                <textarea class="form-control input-group-lg" rows="5" v-model="cfg.note"
                    @change="onChangeNote($event)"></textarea>
            </div>
        </div>
        <div class="clearfix"></div>
    </div>
</template>

<script>
export default {
    name: "dettagli",
    props: {
        cfg: Object,
        msg: Array
    },
    methods: {
        onChangeBombolePiene(event) {
            this.cfg.bombole_piene = event.target.value;
            this.$emit('cfg-dettagli', this.cfg);
        },
        onChangeBomboleVuote(event) {
            this.cfg.bombole_vuote = event.target.value;
            this.$emit('cfg-dettagli', this.cfg);
        },
        onChangeGruppiPieni(event) {
            this.cfg.gruppi_pieni = event.target.value;
            this.$emit('cfg-dettagli', this.cfg);
        },
        onChangeGruppiVuoti(event) {
            this.cfg.gruppi_vuoti = event.target.value;
            this.$emit('cfg-dettagli', this.cfg);
        },
        onChangeConsegnaMediaBombole(event) {
            this.cfg.consegna_media_bombole = event.target.value;
            this.$emit('cfg-dettagli', this.cfg);
        },
        onChangeConsegnaMediaGruppi(event) {
            this.cfg.consegna_media_gruppi = event.target.value;
            this.$emit('cfg-dettagli', this.cfg);
        },
        onChangeConsegnaMediaLiquido(event) {
            this.cfg.consegna_media_liquido = event.target.value;
            this.$emit('cfg-dettagli', this.cfg);
        },
        onChangeFornitore(event) {
            this.cfg.fornitore = event.target.value;
            this.$emit('cfg-dettagli', this.cfg);
        },
        onChangePagamento(event) {
            this.cfg.pagamento = event.target.value;
            this.$emit('cfg-dettagli', this.cfg);
        },
        onChangeScadenzaContratto(event) {
            this.cfg.scadenza_contratto = event.target.value;
            this.$emit('cfg-dettagli', this.cfg);
        },
        onChangeNote(event) {
            this.cfg.note = event.target.value;
            this.$emit('cfg-dettagli', this.cfg);
        }
    }
};
</script>

<style scoped>
.my-box {
    margin-bottom: 20px;
}
</style>
