<template>
    <div>
        <div class="form-row" v-if="index <= gasRefriInseriti - 1">
            <div class="form-group col-md-3 input-group-lg">
                <label for="contenitore">Contenitore</label>
                <select class="form-control" v-model="cfg.contenitore" @change="onChangeContenitore($event)">
                    <option value="0">-</option>
                    <option value="1">Bidone 5kg</option>
                    <option value="2">Bidone 10kg</option>
                    <option value="3">Bidone 40kg</option>
                    <option value="4">Drum</option>
                </select>
            </div>
            <div class="form-group col-md-3 input-group-lg">
                <label for="consumo">Consumo <span class="text-info">(**)</span></label>
                <input type="text" class="form-control" v-model="cfg.consumo" @change="onChangeConsumo($event)">
            </div>
            <div class="form-group col-md-4 input-group-lg">
                <label for="nome_commerciale">Nome Commerciale</label>
                <!-- <input type="text" class="form-control" v-model="cfg.nome_commerciale"
                    @change="onChangeNomeCommerciale($event)"> -->
                <select class="form-control" v-model="cfg.nome_commerciale" @change="onChangeNomeCommerciale($event)">
                    <option value="0">-</option>
                    <option value="1">R134a</option>
                    <option value="2">R404A</option>
                    <option value="3">R407C</option>
                    <option value="4">R410A</option>
                    <option value="5">R507A</option>
                    <option value="6">Altro</option>
                </select>
            </div>
            <div class="form-group col-md-2 input-group-lg">
                <label for="gas"><span class="text-info"><br /></span></label>
                <button class="btn btn-sm btn-danger" type="button" style="margin-top:30px"
                    v-if="gasRefriInseriti == index + 1 && index > 0" @click="UpdateGasRefriInseriti('remove')"><i
                        class="fas fa-trash fa-sm"></i></button>&nbsp;&nbsp;
                <button class="btn btn-sm btn-info" type="button" style="margin-top:30px"
                    v-if="gasRefriInseriti == index + 1 && ((index + 1) < 3)" @click="UpdateGasRefriInseriti('add')"><i
                        class="fas fa-plus fa-sm"></i></button>
            </div>
        </div>
        <div v-if="index == 0">
            <hr class="separatore" />
        </div>
    </div>
</template>

<script>
export default {
    name: "gas-refrig",
    props: {
        cfg: Object,
        index: Number,
        gasRefriInseriti: Number
    },
    methods: {
        onChangeContenitore(event) {
            this.cfg.contenitore = event.target.value;
            this.$emit('cfg-gas-refri', this.cfg);
        },
        onChangeConsumo(event) {
            this.cfg.consumo = event.target.value;
            this.$emit('cfg-gas-refri', this.cfg);
        },
        onChangeNomeCommerciale(event) {
            this.cfg.nome_commerciale = event.target.value;
            this.$emit('cfg-gas-refri', this.cfg);
        },
        UpdateGasRefriInseriti(event) {
            this.gasRefriInseriti = (event == 'remove') ? this.gasRefriInseriti - 1 : this.gasRefriInseriti + 1;
            this.$emit('UpdateGasRefriInseriti', this.gasRefriInseriti);
        }
    }
};
</script>

<style scoped>
.my-box {
    margin-bottom: 20px;
}
</style>
