<template>
  <!-- Modal -->
  <div class="modal fade" id="modalFormQuotazione" tabindex="-1" aria-labelledby="modalFormQuotazione" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalFormQuotazione">Compila le varie sezioni del form ed inviaci la tua richiesta
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="container">
            <form needs-validation novalidate>
              <div class="form-row">
                <div class="col-md-6">

                </div>
                <div class="col-md-6 text-right">
                  <button class="btn btn-lg btn-secondary" type="button" v-if="step > 1 && step < 6 && !invio_in_corso"
                    @click="prev">
                    Indietro
                  </button>&nbsp;&nbsp;&nbsp;&nbsp;
                  <button class="btn btn-lg btn-secondary" type="button" v-if="step == 0 || step <= 4" @click="next">
                    Avanti
                  </button>&nbsp;&nbsp;&nbsp;&nbsp;
                  <button class="btn btn-lg btn-danger" type="button" v-if="step == 5 && !invio_in_corso"
                    @click="inviaRichiesta">
                    Invia richiesta
                  </button>
                  <div class="spinner-border text-primary" role="status" v-if="invio_in_corso">
                    <span class="sr-only">Invio in corso...</span>
                  </div>
                </div>
              </div>

              <!-- Anagrafica -->
              <div v-if="step == 1">
                <h6>Dati anagrafici ( {{ step }}/6 ):</h6><br />
                <div class="form-row">
                  <div class="form-group col-md-6 input-group-lg">
                    <label for="nominativo">Ragione Sociale (*)</label>
                    <input type="text" class="form-control" id="nominativo" placeholder="ragione sociale, nome/cognome"
                      v-model="form.anagrafica.nominativo" required />
                    <span v-if="form.msg.nominativo" class="text-danger">{{ form.msg.nominativo }}</span>
                  </div>
                  <div class="form-group col-md-6 input-group-lg">
                    <label for="piva">P. Iva (*)</label>
                    <input type="number" class="form-control" id="piva" placeholder="partita iva"
                      v-model="form.anagrafica.piva" />
                    <span v-if="form.msg.piva" class="text-danger">{{ form.msg.piva }}</span>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6 input-group-lg">
                    <label for="persona_riferimento">Persona di Riferimento (*)</label>
                    <input type="text" class="form-control" id="persona_riferimento" placeholder=""
                      v-model="form.anagrafica.persona_riferimento" required />
                    <span v-if="form.msg.persona_riferimento" class="text-danger">{{ form.msg.persona_riferimento
                    }}</span>
                  </div>
                  <div class="form-group col-md-6 input-group-lg">
                    <label for="email">Email (*)</label>
                    <input type="email" class="form-control" id="email" placeholder="" v-model="form.anagrafica.email"
                      required />
                    <span v-if="form.msg.email" class="text-danger">{{ form.msg.email }}</span>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-3 input-group-lg">
                    <label for="tel">Tel/Cel (*)</label>
                    <input type="text" class="form-control" id="tel" placeholder="" v-model="form.anagrafica.tel" />
                    <span v-if="form.msg.tel" class="text-danger">{{ form.msg.tel }}</span>
                  </div>
                  <div class="form-group col-md-9 input-group-lg">
                    <label for="indirizzo">Indirizzo destinazione forniture (*)</label>
                    <input type="text" class="form-control" id="indirizzo" placeholder=""
                      v-model="form.anagrafica.indirizzo" />
                    <span v-if="form.msg.indirizzo" class="text-danger">{{ form.msg.indirizzo }}</span>
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-md-4 input-group-lg">
                    <label for="citta">Città (*)</label>
                    <input type="text" class="form-control" id="citta" v-model="form.anagrafica.citta" />
                    <span v-if="form.msg.citta" class="text-danger">{{ form.msg.citta }}</span>
                  </div>
                  <div class="form-group col-md-2 input-group-lg">
                    <label for="cap">Cap (*)</label>
                    <input type="text" class="form-control" id="cap" v-model="form.anagrafica.cap" maxlength="10" />
                    <span v-if="form.msg.cap" class="text-danger">{{ form.msg.cap }}</span>
                  </div>
                  <div class="form-group col-md-2 input-group-lg">
                    <label for="provincia">Provincia (*)</label>
                    <input type="text" class="form-control" id="provincia" v-model="form.anagrafica.provincia"
                      maxlength="2" />
                    <span v-if="form.msg.provincia" class="text-danger">{{ form.msg.provincia }}</span>
                  </div>
                  <div class="form-group col-md-4 input-group-lg">
                    <label for="nazione">Nazione (*)</label>
                    <select class="form-control" v-model="form.anagrafica.nazione">
                      <option value="IT" selected>ITALIA</option>
                    </select>
                    <span v-if="form.msg.nazione" class="text-danger">{{ form.msg.nazione }}</span>
                  </div>
                </div>
              </div>
              <!-- Anagrafica -->

              <!-- Configurazione gas -->
              <div v-if="step == 2">
                <h6>Configurazione Gas ( {{ step }}/6 ):</h6><br />
                <Gas v-for=" (cfg, index) in this.form.configGas" :cfg="cfg" :index="index" @cfg-gas="cfg_gas"
                  :gasInseriti="gasInseriti" @UpdateGasInseriti="UpdateGasInseriti"></Gas>
                <!-- <button class="btn btn-lg btn-secondary" type="button" v-if="this.gasInseriti <= 9" @click="aggiungiGas">
                  Aggiungi gas
                </button> -->
                <div class="form-row">
                  <span><small class="text-info"> (**) consumo medio mensile. Indicare unità di misura
                      m3,l,kg</small></span>
                </div>
              </div>
              <!-- Configurazione gas -->

              <!-- Configurazione miscela -->
              <div v-if="step == 3">
                <h6>Configurazione Miscela ( {{ step }}/6 ):</h6><br />
                <Miscela v-for=" (cfg, index) in form.configMiscela" :cfg="cfg" :index="index" @cfg-miscela="cfg_miscela"
                  :misceleInserite="misceleInserite" @UpdateMisceleInserite="UpdateMisceleInserite">
                </Miscela>
                <div class="form-row">
                  <span><small class="text-info"> (**) consumo medio mensile. Indicare unità di misura
                      m3,l,kg</small></span>
                </div>
              </div>
              <!-- Configurazione miscela -->

              <!-- Configurazione gas refrigerante -->
              <div v-if="step == 4">
                <h6>Configurazione gas refrigerante ( {{ step }}/6 )</h6><br />
                <GasRefrigerante v-for=" (cfg, index) in form.configGasRefri" :cfg="cfg" :index="index"
                  @cfg-gas-refri="cfg_gas_refri" :gasRefriInseriti="gasRefriInseriti"
                  @UpdateGasRefriInseriti="UpdateGasRefriInseriti">
                </GasRefrigerante>
                <div class="form-row">
                  <span><small class="text-info"> (**) consumo medio mensile. Indicare unità di misura
                      m3,l,kg</small></span>
                </div>
              </div>
              <!-- Configurazione gas refrigerante -->

              <!-- Configurazione finale -->
              <div v-if="step == 5">
                <h6>Ultimi dati ed invio richiesta ( {{ step }}/6 )</h6><br />
                <Dettagli :cfg="form.dettagli" :msg="form.msg" :key="step"></Dettagli>
              </div>
              <!-- Configurazione finale -->

              <!-- Esito -->
              <div v-if="step == 6">
                <!-- errore compilazione dati -->
                <div v-if="Object.keys(form.msg).length > 0">
                  <h6 class="text-danger">Attenzione, ci sono alcune informazioni
                    mancanti. <br />
                    Torna indietro e controlla i dati inseriti. Grazie</h6>
                  <button class="btn btn-lg btn-secondary" type="button" @click="inizio">
                    Controlla
                  </button>
                </div>
                <!-- verifico status invio richiesta al server -->
                <div v-if="Object.keys(form.msg).length == 0 && !esito_invio_richiesta">
                  <h6 class="text-danger">Si è verificato un problema durante l'invio della richiesta. <br /> Rivedi i
                    dati
                    inseriti e riprova. Grazie</h6>
                  <button class="btn btn-lg btn-secondary" type="button" @click="inizio">
                    Riprova
                  </button>
                </div>

                <div v-if="Object.keys(form.msg).length == 0 && esito_invio_richiesta">
                  <h6 class="text-success">La richiesta è stata inviata con successo, ti risponderemo al più presto.<br />
                    Riceverai una email di conferma. Grazie</h6>
                </div>

              </div>
              <!-- Esito -->

            </form>
          </div>
        </div>
        <!-- <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Gas from "./Gas.vue";
import Miscela from "./Miscela.vue";
import GasRefrigerante from "./GasRefrigerante.vue";
import Dettagli from "./Dettagli.vue";

export default {
  name: "FormQuotazione",
  data() {
    return {
      invio_in_corso: false,
      esito_invio_richiesta: false,
      step: 1,
      gasInseriti: 1,
      misceleInserite: 1,
      gasRefriInseriti: 1,
      form: {
        msg: [],
        anagrafica: {
          nominativo: "",
          piva: "",
          indirizzo: "",
          citta: "",
          nazione: "",
          provincia: "",
          cap: "",
          tel: "",
          email: "",
          persona_riferimento: ""
        },
        configGas: [
          { index: 0, gas: 0, contenitore: 0, applicazione: 0, consumo: '', nome_commerciale: '' },
          { index: 1, gas: 0, contenitore: 0, applicazione: 0, consumo: '', nome_commerciale: '' },
          { index: 2, gas: 0, contenitore: 0, applicazione: 0, consumo: '', nome_commerciale: '' },
          { index: 3, gas: 0, contenitore: 0, applicazione: 0, consumo: '', nome_commerciale: '' },
          { index: 4, gas: 0, contenitore: 0, applicazione: 0, consumo: '', nome_commerciale: '' },
          { index: 5, gas: 0, contenitore: 0, applicazione: 0, consumo: '', nome_commerciale: '' },
          { index: 6, gas: 0, contenitore: 0, applicazione: 0, consumo: '', nome_commerciale: '' },
          { index: 7, gas: 0, contenitore: 0, applicazione: 0, consumo: '', nome_commerciale: '' },
          { index: 8, gas: 0, contenitore: 0, applicazione: 0, consumo: '', nome_commerciale: '' },
          { index: 9, gas: 0, contenitore: 0, applicazione: 0, consumo: '', nome_commerciale: '' }],
        configMiscela: [
          { index: 0, miscela: 0, contenitore: 0, applicazione: 0, consumo: '', nome_commerciale: '' },
          { index: 1, miscela: 0, contenitore: 0, applicazione: 0, consumo: '', nome_commerciale: '' },
          { index: 2, miscela: 0, contenitore: 0, applicazione: 0, consumo: '', nome_commerciale: '' }],
        configGasRefri: [
          { index: 0, contenitore: 0, consumo: '', nome_commerciale: '' },
          { index: 1, contenitore: 0, consumo: '', nome_commerciale: '' },
          { index: 2, contenitore: 0, consumo: '', nome_commerciale: '' }],
        dettagli: {
          bombole_piene: "",
          bombole_vuote: "",
          gruppi_pieni: "",
          gruppi_vuoti: "",
          consegna_media_bombole: "",
          consegna_media_gruppi: "",
          consegna_media_liquido: "",
          fornitore: "",
          pagamento: "",
          scadenza_contratto: "",
          note: ""
        }
      },
    };
  },
  watch: {
    'form.anagrafica.email'(value) {
      this.form.anagrafica.email = value;
      this.validateEmail(value);
    }
  },
  methods: {
    validateEmail(value) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
        this.form.msg['email'] = '';
      } else {
        this.form.msg['email'] = 'Email non corretta';
      }
    },
    prev() {
      this.step = this.step - 1;
      if (this.step < 0)
        this.step = 0;
    },
    next() {
      this.validaCampi(this.step);
      this.step = this.step + 1;
      if (this.step > 6)
        this.step = 6;
    },
    validaCampi(step) {
      switch (step) {
        case 1:
          if (this.form.anagrafica.nominativo.trim() == '') this.form.msg['nominativo'] = ' ! dato mancante';
          else { delete this.form.msg['nominativo']; }

          if (this.form.anagrafica.piva.trim() == '') this.form.msg['piva'] = ' ! dato mancante';
          else { delete this.form.msg['piva']; }

          if (this.form.anagrafica.email.trim() == '') this.form.msg['email'] = ' ! dato mancante';
          else { delete this.form.msg['email']; }

          if (this.form.anagrafica.tel.trim() == '') this.form.msg['tel'] = ' ! dato mancante';
          else { delete this.form.msg['tel']; }

          if (this.form.anagrafica.indirizzo.trim() == '') this.form.msg['indirizzo'] = ' ! dato mancante';
          else { delete this.form.msg['indirizzo']; }

          if (this.form.anagrafica.citta.trim() == '') this.form.msg['citta'] = ' ! dato mancante';
          else { delete this.form.msg['citta']; }

          if (this.form.anagrafica.cap.trim() == '') this.form.msg['cap'] = ' ! dato mancante';
          else { delete this.form.msg['cap']; }

          if (this.form.anagrafica.provincia.trim() == '') this.form.msg['provincia'] = ' ! dato mancante';
          else { delete this.form.msg['provincia']; }

          if (this.form.anagrafica.nazione.trim() == '') this.form.msg['nazione'] = ' ! dato mancante';
          else { delete this.form.msg['nazione']; }

          if (this.form.anagrafica.persona_riferimento.trim() == '') this.form.msg['persona_riferimento'] = ' ! dato mancante';
          else { delete this.form.msg['persona_riferimento']; }

          break;
        case 2:
        case 3:
        case 4:
          break;
        case 5:
          if (this.form.dettagli.bombole_piene.trim() == '') this.form.msg['bombole_piene'] = ' ! dato mancante';
          else { delete this.form.msg['bombole_piene']; }

          if (this.form.dettagli.bombole_vuote.trim() == '') this.form.msg['bombole_vuote'] = ' ! dato mancante';
          else { delete this.form.msg['bombole_vuote']; }

          if (this.form.dettagli.scadenza_contratto.trim() == '') this.form.msg['scadenza_contratto'] = ' ! dato mancante';
          else { delete this.form.msg['scadenza_contratto']; }

          break;
        default: break;
      }
      return true;

    },
    cfg_gas(cfg) {
      let tmp = this.form.configGas.filter(e => e.index != cfg.index)
      tmp.push(cfg);
      this.form.configGas = tmp;
      this.form.configGas.sort((a, b) => a.index - b.index)
    },
    cfg_miscela(cfg) {
      let tmp = this.form.configMiscela.filter(e => e.index != cfg.index)
      tmp.push(cfg);
      this.form.configMiscela = tmp;
      this.form.configMiscela.sort((a, b) => a.index - b.index)
    },
    cfg_gas_refri(cfg) {
      let tmp = this.form.configGasRefri.filter(e => e.index != cfg.index)
      tmp.push(cfg);
      this.form.configGasRefri = tmp;
      this.form.configGasRefri.sort((a, b) => a.index - b.index)
    },
    UpdateGasInseriti(valore) {
      this.gasInseriti = valore;
    },
    UpdateMisceleInserite(valore) {
      this.misceleInserite = valore;
    },
    UpdateGasRefriInseriti(valore) {
      this.gasRefriInseriti = valore;
    },
    inizio() {
      this.step = 1;
    },
    inviaRichiesta() {
      this.invio_in_corso = false;
      console.log("invia richiesta");
      console.log(this.form);
      this.validaCampi(this.step);

      if (Object.keys(this.form.msg).length <= 0) {
        this.invio_in_corso = true;
        axios
          .post("http://localhost:8080/virtualgas.it/crm/api/interact.php", { dati: this.form })
          .then((res) => {
            console.log(res.data.status);
            if (!res.data.status) this.esito_invio_richiesta = false;
            else this.esito_invio_richiesta = true;
          })
          .catch((error) => {
            this.esito_invio_richiesta = false;
          })
          .finally(() => {
            this.invio_in_corso = false;
            this.next();
          });
      } else {
        this.next();
      }
    },
  },
  components: { Gas, Miscela, GasRefrigerante, Dettagli }
};
</script>
<style>
.separatore {
  border: none;
  height: 1px;
  background: #000;
  background: repeating-linear-gradient(90deg, #000, #000 6px, transparent 6px, transparent 12px);
}
</style>
