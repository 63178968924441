<template>
  <div>
    <header class="text-black text-center" id="quotazione">
      <div class="container d-flex align-items-center flex-column" style="width:40%">
        <!-- Masthead Avatar Image-->
        <!-- <img class=" masthead-avatar mb-5" src="../assets/img/logo.jpg" alt="..." /> -->
        <!-- Masthead Heading-->
        <h5 class="page-section-heading text-center text-uppercase mb-0"
          style="font-size: 25px; padding-top: 25px; color:#7bbce1">RICHIEDI
          UNA QUOTAZIONE</h5>
        <br />
        <!-- Icon Divider-->
        <!-- <div class="divider-custom divider-light">
          <div class="divider-custom-line"></div>
          <div class="divider-custom-icon"><i class="fas fa-star"></i></div>
          <div class="divider-custom-line"></div>
        </div> -->
        <!-- Masthead Subheading-->
        <br />
        <p class="masthead-subheading font-weight-light mb-0" style="color:#000000">Registrati al portale ed inviaci una
          tua richiesta. Ti forniremo quanto prima le nostre migliori soluzioni</p>
        <br />
        <a href="https://virtualgas.it/customer" target="_blank"><button type="button" class="btn btn-danger btn-sm"
            style="color: #FFF; background-color:#176ae0; border-color: #176ae0; font-weight: bold;"
            title="Registrati oppure accedi alla tua area riservata">COMPILA IL FORM</button></a>
      </div>
      <br /><br />
    </header>
    <FormQuotazione></FormQuotazione>

    <div class="modal fade" id="loginModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-title text-center">
              <h4>Login</h4>
            </div>
            <div class="d-flex flex-column text-center">
              <form>
                <div class="form-group">
                  <input type="email" class="form-control" id="email1" placeholder="Inserisci la tua Email">
                </div>
                <div class="form-group">
                  <input type="password" class="form-control" id="password1" placeholder="digita la tua password">
                </div>
                <button type="button" class="btn btn-info btn-block btn-round">Login</button>
              </form>

              <div class="text-center text-muted delimiter">seguici su linkedin</div>
              <div class="d-flex justify-content-center social-buttons">
                <button type="button" class="btn btn-secondary btn-round" data-toggle="tooltip" data-placement="top"
                  title="Linkedin">
                  <i class="fab fa-linkedin"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <div class="signup-section">Se non hai ancora un account <a href="#a" class="text-info"> Registrati</a>.</div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import FormQuotazione from './FormQuotazione.vue'
export default {
  name: 'Header',
  components: { FormQuotazione }
}
</script>
