<template>
    <section class="masthead page-section" id="about_small">
        <div class="container">
            <!-- About Section Content-->
            <div class="row">
                <div class="col-sm-12 text-center">
                    Virtual Gas è una giovane società tecnologica
                    italiana che opera come sito di
                    comparazione,<br />
                    promozione e intermediazione di fornitura di gas tecnici e industriali. L'obiettivo è di offrire un
                    servizio
                    personalizzato, gratuito e rapido.<br />
                    <a href="#about">Scopri di più</a>
                    <br /><br />
                </div>
            </div>
            <div class="col-sm-2"></div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'ChiSiamoSmall'
}
</script>