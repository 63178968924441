<template>
    <footer class="text-center text-white" style="background-color: #7bbce1; padding: 20px;">
        <div class=" container mt-4 pt-5 pb-2">

            <div class="row">
                <div class="col-lg-3 col-md-12" p-1 m-1>
                    <img src="../assets/img/location.png" width="20%" class="m-1 p-2"><br />
                    <span><strong>LA NOSTRA SEDE</strong></span><br />
                    <p>Via Volturno, 10 - 20089 <br />Rozzano (MI)</p>
                </div>
                <div class="col-lg-3 col-md-12">
                    <img src="../assets/img/phone-call.png" width="20%" class="m-1 p-2"><br />
                    <span><strong>TELEFONO</strong></span><br />
                    <p>+39 327 602 4239</p>
                </div>
                <div class="col-lg-3 col-md-12">
                    <img src="../assets/img/mail.png" width="20%" class="m-1 p-2"><br />
                    <span><strong>EMAIL</strong></span><br />
                    <p>info@virtualgas.it</p>
                </div>
                <div class="col-lg-3 col-md-12">
                    <img src="../assets/img/programmer.png" width="20%" class="m-1 p-2"><br />
                    <span><strong>DATI AZIENDALI</strong></span><br />
                    <p>P.IVA/CF: 12840330968</p>
                </div>

            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer'
}
</script>
