<template>
  <div id="app">
    <Menu></Menu>
    <ChisiamoSmall></ChisiamoSmall>
    <Gallery></Gallery>
    <Header></Header>
    <NewslettersBanner></NewslettersBanner>
    <!-- <Partners></Partners> -->
    <ChiSiamo></ChiSiamo>
    <Footer></Footer>
    <Copy></Copy>
  </div>
</template>

<script>
import Menu from './components/Menu.vue'
import Header from './components/Header.vue'
// import Partners from './components/Partners.vue'
import NewslettersBanner from './components/NewslettersBanner.vue'
import ChiSiamo from './components/ChiSiamo.vue'
import Footer from './components/Footer.vue'
import Copy from './components/CopyRight.vue'
import Gallery from './components/Gallery.vue';
import ChisiamoSmall from './components/ChiSiamoSmall.vue';

import "./assets/js/script.js";

export default {
  name: 'App',
  components: {
    Menu,
    ChisiamoSmall,
    Gallery,
    Header,
    NewslettersBanner,
    //Partners,
    ChiSiamo,
    Footer,
    Copy
  }
}
</script>

<style>
@import "./assets/css/common.css";
@import "./assets/css/flip.css";
@import "./assets/css/login.css";
@import "./assets/css/custom.css";
</style>

