<template>
    <section class="page-section portfolio" id="newsLettersBanner" style="background-color: #177ce7; padding: 20px;"
        v-if="Object.keys(news).length > 0">
        <div class="container">
            <!-- Portfolio Section Heading-->
            <h5 class="page-section-heading text-center text-uppercase  mb-0"
                style="padding-top:20px; font-size: 25px; color:#FFF">News
            </h5>
            <br />
            <br />
            <!-- Icon Divider-->
            <!-- <div class="divider-custom">
                <div class="divider-custom-line"></div>
                <div class="divider-custom-icon"><i class="fas fa-star"></i></div>
                <div class="divider-custom-line"></div>
            </div> -->
            <!-- Portfolio Grid Items-->
            <div class="row col-sm-12 text-center">

                <template v-for="nn in  news ">
                    <div class="row" style="color:#FFF; border-bottom: 1px solid #FFF; padding: 20px;margin-top: 8px;">
                        <div class="col-md-4 text-left" style="font-weight: bold;"><a v-bind:href="nn.fonte" target="_blank"
                                style="cursor: grab; text-decoration: none;color:#FFF;"
                                title="Clicca per scoprire i dettagli">{{
                                    nn.titolo }}</a></div>
                        <div class="col-md-8 text-right">{{ nn.descrizione }}
                            <br /><small><em>{{ nn.mese }} {{ nn.anno }}</em></small>
                        </div>
                    </div>
                </template>

            </div>

            <!-- <div class="row col-sm-12 text-center">
                <div class="row" style="color:#FFF; border-bottom: 1px solid #FFF; padding: 20px;margin-top: 8px;">
                    <div class="col-md-4 text-left" style="font-weight: bold;"><a @click="apriNews(4)" style="cursor: grab;"
                            title="Clicca per scoprire i dettagli">Cibus 7-10 Maggio 2024</a></div>
                    <div class="col-md-8 text-right">22° Salone Internazione dell'Alimentazione. Parma dal 7 al 10 Maggio
                        2024
                        <br /><small><em>Novembre 2023</em></small>
                    </div>
                </div>
                <div class="row" style="color:#FFF; border-bottom: 1px solid #FFF; padding: 20px;margin-top: 8px;">
                    <div class="col-md-4 text-left" style="font-weight: bold;"><a @click="apriNews(5)" style="cursor: grab;"
                            title="Clicca per scoprire i dettagli">Emerson: Tecnologia e Sostenibilità</a></div>
                    <div class="col-md-8 text-right">Soluzioni digitali aiutano Toyota a incorporare la produzione
                        sostenibile di combustibile.
                        Emerson fornisce tecnologia di automazione avanzata per l'impianto di produzione e rifornimento di
                        idrogeno di Toyota Australia.
                        <br /><small><em>Novembre 2023</em></small>
                    </div>
                </div>
                <div class="row" style="color:#FFF; border-bottom: 1px solid #FFF; padding: 20px;margin-top: 8px;">
                    <div class="col-md-4 text-left" style="font-weight: bold;"><a @click="apriNews(2)" style="cursor: grab;"
                            title="Clicca per scoprire i dettagli">Position Paper sulla proposta di revisione
                            del Regolamento F-Gas</a></div>
                    <div class="col-md-8 text-right">Il documento, redatto dal Comitato Gas Refrigeranti di Assogastecnici,
                        intende illustrare la posizione del settore sulla proposta di revisione del Regolamento (UE)
                        517/2014 (Regolamento F-Gas).
                        <br /><small><em>Ottobre 2023</em></small>
                    </div>
                </div>
                <div class="row" style="color:#FFF; border-bottom: 1px solid #FFF; padding: 20px;margin-top: 8px;">
                    <div class="col-md-4 text-left" style="font-weight: bold;"><a @click="apriNews(3)" style="cursor: grab;"
                            title="Clicca per scoprire i dettagli">Proroga al decreto controlli</a></div>
                    <div class="col-md-8 text-right">Con il Decreto del Ministero dell’Interno 15 settembre 2022 (GURI del
                        24 settembre 2022), le disposizioni previste all’art. 4 D.M 1° settembre 2021, relative alla
                        qualificazione dei tecnici manutentori hanno subito una proroga: esse entrano in vigore a decorrere
                        dal 25 settembre 2023.
                        <br /><small><em>Ottobre 2023</em></small>
                    </div>
                </div>
            </div> -->
            <div class="row col-sm-12 text-center" style="margin-top: 20px;">
                <a href="/news" hidden>Visualizza tutte le news</a>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'NewslettersBanner',
    data() {
        return {
            news: ['']
        }
    },
    mounted() {
        axios
            .get('https://www.virtualgas.it/crm/api/latestNews.php')
            .then(response => (
                this.news = (response && response.data) ? response.data : []
            ))
    },
    methods: {
        apriNews(url) {
            window.open(url, "_blank");
            // switch (id) {
            //     case 2:
            //         window.open("https://assogastecnici.federchimica.it/attivita/dettaglioevento/2023/02/17/default-calendar/position-paper-sulla-proposta-di-revisione-del-regolamento-f-gas", "_blank");
            //         break;
            //     case 3:
            //         window.open("https://www.anima.it/associazioni/elenco/uman/media/news/tutte-le-news/proroga-al-decreto-controlli.kl", "_blank");
            //         break;
            //     case 4:
            //         window.open("https://www.cibus.it/", "_blank");
            //         break;
            //     case 5:
            //         window.open("https://www.emerson.com/it-it/news/automation/22-5-toyota-hydrogen-production-plant-sustainable-solutions", "_blank");
            //         break;
            //     default:
            //         break;
            //}
        }
    }
}
</script>